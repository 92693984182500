$smallWidthLocal: 576px;
$mediumWidthLocal: 768px;
$largeWidthLocal: 992px;
$xLargeWidthLocal: 1200px;

@mixin xLargeAndBelowLocal {
  @media (max-width: #{ $xLargeWidthLocal }) {
    @content;
  }
}

@mixin largeAndBelowLocal {
  @media (max-width: #{ $largeWidthLocal }) {
    @content;
  }
}

@mixin largeOnlyLocal {
  @media (min-width: #{ $largeWidthLocal }) and (max-width: #{ $xLargeWidthLocal - 1px }) {
    @content;
  }
}

@mixin mediumAndBelowLocal {
  @media (max-width: #{ $mediumWidthLocal }) {
    @content;
  }
}

@mixin mediumOnlyLocal {
  @media (min-width: #{ $mediumWidthLocal }) and (max-width: #{ $largeWidthLocal - 1px }) {
    @content;
  }
}

@mixin smallAndBelowLocal {
  @media (max-width: #{ $smallWidthLocal }) {
    @content;
  }
}
