@use './' as *;

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: $mainFont, sans-serif;
  margin: 0;
}

.clickable {
  cursor: pointer;
  text-decoration: none;
}

.clickable:hover {
  opacity: 0.8;
}

.highlight {
  border-radius: 12px;
  border: 5px solid #5ac8fa;
  padding: 5px;
}

.no-select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
}

.content-padding {
  padding: 26px;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-100vh {
  width: 100vh;
}

.h-100vh {
  height: 100vh;
}

.border-0 {
  border: 0;
}

.flex {
  display: flex;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-space-between {
  justify-content: space-between;
}

.wrap {
  flex-wrap: wrap;
}

.center-align {
  align-items: center;
  display: flex;
  justify-content: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.card {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(221, 221, 221);
  border-radius: 12px;
  overflow: hidden;
  padding: 32px;

  @include smallAndBelowLocal {
    padding: 16px;
  }
}

.button-shadow {
  box-shadow: 10px 24px 40px -10px rgba(0, 0, 0, 0.5);
}

.component-shadow {
  box-shadow: 10px 24px 40px -10px rgba(0, 0, 0, 0.5);
}

.component-shadow-small {
  box-shadow: 0 24px 40px -10px rgba(0, 0, 0, 0.25);
}

#app-background {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}

/* NProgress styles */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #03a9f4;
  height: 1px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1031;
}

/* react-dropdown-select styles */
.react-dropdown-select-input,
.react-dropdown-select-content {
  @include font_body;

  font-size: 16px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.z-100 {
  z-index: 100;
}

.self-end {
  align-self: flex-end;
}

.schedule-grid {
  display: grid;
  grid-template-columns: repeat(7, 14.2%);
}

.flex-1 {
  flex: 1;
}

.desktop-local-switch-position {
  position: absolute;
  top: 25px;
  right: 20px;
  z-index: 10;

  @include mediumAndBelowLocal {
    top: 10px;
    right: 10px;
  }
}
