// font colors
$font_color_w100: rgba(255, 255, 255, 1);
$font_color_w80: rgba(255, 255, 255, 0.8);
$font_color_w70: rgba(255, 255, 255, 0.7);
$font_color_w64: rgba(255, 255, 255, 0.64);
$font_color_w40: rgba(255, 255, 255, 0.4);
$font_color_w24: rgba(255, 255, 255, 0.24);
$font_color_cta-blue: rgba(2, 207, 242, 1);
$font_color_main: #03a9f4;
$font_color_sb: rgb(204, 204, 204);
$font_color_sm: rgb(122, 122, 122);
$font_color_sd: rgb(64, 64, 64);
$font_color_b100: rgba(0, 0, 0, 1);
$font_color_b80: rgba(0, 0, 0, 0.8);
$font_color_b64: rgba(0, 0, 0, 0.64);
$font_color_b40: rgba(0, 0, 0, 0.4);
$font_color_b24: rgba(0, 0, 0, 0.24);
$font_color_dark_grey: #4e545f;
$font_color_black_grey: #2f3237;
$font_color_dark_grey: #4e545f;
$font_color_grey: #9092a3;
$font_color_black_grey_icon: #5b657a;
$font_color_error: #e53b3b;

// font weights
$thin: 100;
$ultraLight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$heavy: 800;
$black: 900;

// font families
$mainFont: 'Proxima';

@font-face {
  font-display: swap;
  font-family: $mainFont;
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/ProximaNova-Regular.otf');
}
@font-face {
  font-display: swap;
  font-family: $mainFont;
  font-style: normal;
  font-weight: 600;
  src: url('/static/fonts/ProximaNova-Semibold.otf');
}
@font-face {
  font-display: swap;
  font-family: $mainFont;
  font-style: normal;
  font-weight: 700;
  src: url('/static/fonts/ProximaNova-Bold.otf');
}

@mixin custom_font_main {
  font-family: $mainFont !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@mixin font_h1 {
  @include custom_font_main();
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  text-shadow: 0;
}

@mixin font_h2_large {
  @include custom_font_main();
  font-size: 36px;
  font-weight: 600;
  line-height: 48px;
  text-shadow: 0;
}

@mixin font_h2 {
  @include custom_font_main();
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  text-shadow: 0;
}

@mixin font_h2_small {
  @include custom_font_main();
  font-size: 28px;
  font-weight: 700;
  line-height: 48px;
  text-shadow: 0;
}

@mixin font_h3 {
  @include custom_font_main();
  font-size: 22px;
  font-weight: 500;
  line-height: 36px;
  text-shadow: 0;
}

@mixin font_h3_large {
  @include custom_font_main();
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 0;
  margin-top: 0;
  text-shadow: 0;
}

@mixin font_h3_small {
  @include custom_font_main();
  font-size: 22px;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 0;
  margin-top: 0;
  text-shadow: 0 0 0;
}

@mixin font_h4 {
  @include custom_font_main();
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-shadow: 0;
}

@mixin font_h4_large {
  @include custom_font_main();
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 0;
  margin-top: 0;
  text-shadow: 0;
}

@mixin font_cta {
  @include custom_font_main();
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
}

@mixin font_body {
  @include custom_font_main();
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
}

@mixin font_body2 {
  @include custom_font_main();
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
}

@mixin chat_input {
  @include custom_font_main();
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

@mixin font_subtitle {
  @include custom_font_main();
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}

@mixin font_subtitle2 {
  @include custom_font_main();
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

@mixin font_footnote {
  @include custom_font_main();
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

@mixin font_footnote2 {
  @include custom_font_main();
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}

@mixin title {
  @include custom_font_main();
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
}

@mixin title1 {
  @include custom_font_main();
  font-size: 24px;
  font-weight: $semibold;
  line-height: 29px;
}

@mixin badge_text {
  @include custom_font_main();
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
}
